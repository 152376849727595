/* 

  Este arquivo CSS estiliza o rodapé do site, incluindo a disposição dos elementos, cores, espaçamentos e ajustes responsivos para diferentes tamanhos de tela.

  .footer-container:
    - Container principal do rodapé com layout flexível, espaçamento e cores.

  .footer-section:
    - Seções do rodapé organizadas verticalmente com espaçamento entre itens.

  .footer-section a:
    - Estilização dos links dentro das seções do rodapé, incluindo efeito hover.

  .footer-section a img:
    - Estilização das imagens dentro dos links das seções do rodapé.

  .footer-images:
    - Container para imagens no rodapé, organizadas verticalmente com espaçamento.

  .footer-images img:
    - Tamanho padrão das imagens no rodapé.

  .footer-images img:nth-child(2):
    - Tamanho específico para a segunda imagem no rodapé.

  #brasao-lsd:
    - Estilização específica para a imagem do brasão.

  .copyright:
    - Estilização da seção de copyright no rodapé.

  .footer-parceiros:
    - Container para parceiros no rodapé, com layout flexível e espaçamento.

  @media (max-width: 1050px):
    - Ajustes de layout e espaçamento para telas menores (até 1050px).

  @media (max-width: 480px):
    - Ajustes de layout, espaçamento e tamanho de fonte para telas muito pequenas (até 480px).
*/
/* Footer.css */
.footer-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
  background-color: #0099ff;
  color: #fff;
  flex-wrap: wrap; /* Permite que os itens se ajustem em telas menores */
}

/* Seções do footer organizadas verticalmente com espaço entre itens */
.footer-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Links com efeito hover */
.footer-section a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-items: center;
}

.footer-section a:hover {
  color: #130080;
}

.footer-section a img {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

/* Imagens do footer organizadas com espaço */
.footer-images {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Tamanho padrão das imagens */
.footer-images img {
  width: 130px;
  height: auto;
}

.footer-images img:nth-child(2) {
  width: 230px;
}

#brasao-lsd {
  width: 200px;
  height: auto;
}

/* Copyright */
.copyright {
  padding: 5px;
  text-align: center;
  font-size: 0.8rem;
  background-color: #0077cc;
  color: #fff;
}

.footer-parceiros {
  display: flex;
  justify-content: flex-start; /* Garante alinhamento à esquerda */
  align-items: flex-start; /* Garante alinhamento no topo */
  gap: 20px;
  flex-wrap: wrap; /* Permite quebra de linha em telas pequenas */
  margin-top: 20px;
  width: 100%; /* Garante que ocupe a largura total */
}

/* Ajustes para telas menores */
@media (max-width: 1050px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start; /* Não centraliza em telas menores */
    gap: 40px;
    padding: 20px 10px; /* Ajusta o padding */
  }
  
  .footer-section {
    align-items: flex-start;
    gap: 20px;
    margin: 0;
    width: 100%;
  }

  .footer-section:nth-child(2), .footer-section:nth-child(3) {
    width: 90%; /* Ajusta a largura das seções */
  }

  .footer-parceiros {
    flex-direction: row; /* Mantém os parceiros em linha */
    justify-content: space-between; /* Alinha os parceiros à esquerda */
    gap: 20px; /* Ajusta o espaçamento */
    width: 100%;
  }

  .footer-images {
    gap: 15px; /* Ajusta o gap */
  }

  .footer-images img {
    width: 140px;
  }

  #brasao-lsd {
    width: 140px;
    max-width: 100%; /* Evita que a imagem ultrapasse o limite */
  }
}

/* Ajustes para telas muito pequenas (mobile) */
@media (max-width: 480px) {
  .footer-section a {
    font-size: 0.9rem; /* Diminui o tamanho da fonte para links em telas muito pequenas */
  }

  .footer-section img {
    width: 18px; /* Ajusta o tamanho das imagens dos links */
  }

  .footer-images img {
    width: 120px; /* Ajusta o tamanho das imagens dos parceiros */
  }

  #brasao-lsd {
    width: 120px;
  }
}
