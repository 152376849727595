/* 

  Estilos Gerais da Página de Notícias:
  - .news-page: Define a largura máxima, centraliza o conteúdo e adiciona padding.
  - .news-page h1: Estiliza o título principal com tamanho de fonte, cor, alinhamento e margem inferior.
  - .news-list: Define um layout de grade responsivo para a lista de notícias.
  - .post-item: Estiliza cada item de post com fundo branco, bordas arredondadas, sombra, transição e cursor de ponteiro.
  - .post-item:hover: Adiciona efeito de hover nos itens de post.
  - .post-image: Define a largura e altura da imagem do post.
  - .post-image img: Ajusta a imagem dentro do contêiner.
  - .post-item h2: Estiliza o título do post.
  - .post-item p: Estiliza o parágrafo do post.

  Paginação:
  - .pagination: Define o layout de flexbox para os botões de paginação.
  - .pagination button: Estiliza os botões de paginação com cor de fundo, cor do texto, padding, tamanho da fonte, bordas arredondadas e transição.
  - .pagination button:hover: Adiciona efeito de hover nos botões de paginação.
  - .pagination button:disabled: Estiliza os botões desabilitados.

  Container de Busca:
  - .search-container: Define o layout de flexbox para o container de busca.
  - .search-container input: Estiliza o campo de entrada de busca.
  - .search-container button: Estiliza o botão de busca.

  Responsividade:
  - @media (max-width: 1024px): Ajusta o layout e estilos para telas menores que 1024px.
  - @media (max-width: 768px): Ajusta o layout e estilos para telas menores que 768px.
  - @media (max-width: 480px): Ajusta o layout e estilos para telas menores que 480px.
*/
/* Estilos Gerais da Página de Notícias */
.news-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.news-page h1 {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.news-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.post-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.post-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.post-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.post-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.post-item h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
  font-weight: bold;
}

.post-item p {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
  line-height: 1.5;
}

/* Paginação */
.pagination {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
}

.pagination button {
  background-color: #0099ff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #007bff;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.search-container{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  gap: 20px;
}

.search-container input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
}

.search-container button {
  background-color: #0099ff;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-container button:hover {
  background-color: #007bff;
}


/* Responsividade */
@media (max-width: 1024px) {
  .news-page {
    padding: 15px; /* Reduz padding em telas menores */
  }

  .news-page h1 {
    font-size: 2rem; /* Diminui o tamanho do título */
  }

  .news-list {
    grid-template-columns: repeat(
      auto-fill,
      minmax(250px, 1fr)
    ); /* Ajusta para uma coluna mais estreita */
  }

  .post-image {
    height: auto; /* Ajusta a altura da imagem */
    width: 100%;
  }

  .post-image img {
    height: 100%; /* Ajusta a altura da imagem */
    width: auto;
  }

  .post-item {
    padding: 12px; /* Ajusta o padding nos posts */
  }

  .post-item h2 {
    font-size: 1.3rem; /* Reduz o tamanho do título do post */
  }

  .post-item p {
    font-size: 0.95rem; /* Reduz o tamanho do texto */
  }

  .pagination button {
    font-size: 0.9rem; /* Ajusta o tamanho da fonte nos botões da paginação */
  }
}

@media (max-width: 768px) {
  .news-page {
    padding: 10px; /* Reduz ainda mais o padding */
  }

  .news-page h1 {
    font-size: 1.8rem; /* Diminui mais o título em telas menores */
    margin-bottom: 20px; /* Diminui o espaço abaixo do título */
  }

  .news-list {
    grid-template-columns: 1fr; /* Exibe os posts em uma coluna */
  }

  .post-item {
    padding: 10px; /* Ajusta o padding do post */
  }

  .pagination {
    flex-direction: column; /* Coloca os botões de paginação em coluna */
    gap: 10px; /* Ajusta o espaçamento entre os botões */
  }

  .pagination button {
    font-size: 0.85rem; /* Diminui o tamanho da fonte da paginação */
    padding: 8px 15px; /* Ajusta o padding */
  }
}

@media (max-width: 480px) {
  .news-page {
    padding: 10px; /* Padding reduzido ainda mais em dispositivos muito pequenos */
  }

  .news-page h1 {
    font-size: 1.6rem; /* Título bem menor em telas pequenas */
    margin-bottom: 15px; /* Diminui o espaço abaixo do título */
  }

  .news-list {
    grid-template-columns: 1fr; /* Apenas uma coluna */
  }

  .post-item {
    padding: 8px; /* Ajusta ainda mais o padding dos posts */
  }

  .post-item h2 {
    font-size: 1.2rem; /* Ajusta o título */
  }

  .post-item p {
    font-size: 0.9rem; /* Ajusta o texto */
  }

  .pagination {
    flex-direction: column; /* Botões em coluna */
    gap: 10px; /* Aumenta o gap entre os botões */
  }

  .pagination button {
    font-size: 0.8rem; /* Tamanho de fonte reduzido */
    padding: 7px 12px; /* Ajusta o padding */
  }

  .search-container {
    flex-direction: column;
    gap: 10px;
  }

  .search-container input {
    width: 100%;
  }

  .search-container button {
    width: 100%;
  }
}
