/* 

  Este arquivo CSS define os estilos para a página de post do site Lar São Domingos.

  Variáveis:
  - Cores, fontes e tamanhos são definidos usando variáveis CSS no :root.

  Containers:
  - .post-page-container: Container geral da página.
  - .post-content-container: Container do conteúdo do post.
  - .featured-image-container: Container da imagem de destaque.
  - .slider: Estilos para o slider (MainSlider).
  - .post: Container do post.
  - .post-title-container: Container do título do post.
  - .post-date-container: Container da data de publicação.
  - .post-body-container: Container do corpo do post.
  - .wp-video: Estilos para vídeos do post.
  - .post-video: Estilos para conteúdo de mídia.

  Elementos:
  - .featured-image-img: Estilos para a imagem de destaque.
  - .post-title: Estilos para o título do post.
  - .post-date: Estilos para a data de publicação.
  - .post-content: Estilos para o conteúdo do post.
  - .wp-video .wp-video-shortcode: Estilos para vídeos incorporados.
  - .post-page-container a: Estilos para links e botões.

  Responsividade:
  - Estilos responsivos para diferentes larguras de tela (1024px, 768px, 480px).

  Notas:
  - Utiliza flexbox para alinhamento e layout.
  - Inclui sombras e bordas arredondadas para elementos visuais.
  - Ajustes de padding e margens para melhorar o layout em diferentes tamanhos de tela.
*/
/* Definindo variáveis para cores, fontes e tamanhos */
:root {
  --primary-color: #232323;
  --secondary-color: #333;
  --background-color: #f7f7f7;
  --white-color: #fff;
  --highlight-color: #0073e6; /* Cor para links e destaque */
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  --font-family: "Arial", sans-serif;
  --base-font-size: 1rem;
  --heading-font-size: 2.5rem;
  --small-heading-font-size: 2rem;
  --button-font-size: 1rem;
  --max-width: 1200px;
}

/* Container geral da página */
.post-page-container {
  width: 100%;
  margin-inline: auto;
  overflow-x: hidden;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0;
}

/* Container do post */
.post-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--max-width);
  margin: 0 auto;
}

/* Imagem de destaque */
.featured-image-container {
  width: 100%;
  margin-bottom: 1.25rem;
}

.featured-image-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}

/* Slider (MainSlider) */
.slider {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

/* Conteúdo do Post */
.post {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--max-width);
  margin-top: 8rem;
  padding: 0 2rem;
  width: 100%;
}

/* Título do post */
.post-title-container {
  text-align: center;
  margin-bottom: 1.5rem;
}

.post-title {
  font-size: var(--heading-font-size);
  color: var(--primary-color);
  font-weight: 700;
  text-align: center;
  font-family: var(--font-family);
}

/* Data de publicação */
.post-date-container {
  text-align: center;
  margin-bottom: 1.5rem;
}

.post-date {
  font-size: 1rem;
  color: var(--secondary-color);
}

/* Corpo do Post */
.post-body-container {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  background-color: var(--white-color);
  padding: 2rem;
  box-shadow: var(--box-shadow);
  border-radius: 8px;
}

.post-content {
  font-size: 1.1rem;
  color: var(--secondary-color);
  line-height: 1.8;
  text-align: justify;
  font-family: var(--font-family);
  margin-bottom: 2rem;
  width: 100%;
}

/* Vídeos do Post */
.wp-video {
  width: 100%;
  max-width: 100%; /* Garantir que o vídeo ocupe toda a largura disponível */
  margin: 2rem auto;
  text-align: center;
}

.wp-video .wp-video-shortcode {
  width: 100%;
  max-width: 100%; /* Garantir que o vídeo se ajuste corretamente */
  margin-bottom: 1.5rem;
  height: auto; /* Ajustar a altura do vídeo de forma responsiva */
}

/* Links e botões */
.post-page-container a {
  color: var(--highlight-color);
  text-decoration: none;
}

.post-page-container a:hover {
  text-decoration: underline;
}

/* Responsividade */
@media (max-width: 1024px) {
  .featured-image-img {
    height: 350px; /* Ajusta a altura da imagem */
    object-fit: cover;
  }

  .post-title {
    font-size: 2.2rem;
  }

  .post-content {
    font-size: 1rem;
  }

  .post-body-container {
    padding: 1.5rem; /* Reduz o padding para melhorar o layout */
  }
}

@media (max-width: 768px) {
  .post-title {
    font-size: var(--small-heading-font-size);
    text-align: left;
  }

  .post-content {
    font-size: 1rem;
    line-height: 1.6; /* Melhorar a legibilidade */
  }

  .featured-image-img {
    height: 250px; /* Ajuste a imagem para tamanhos menores */
    object-fit: cover;
  }

  .post-body-container {
    padding: 1rem;
    /* margin-top: 60px; */
  }

  .wp-video {
    margin: 1rem auto; /* Ajustar o espaçamento dos vídeos */
  }
}

@media (max-width: 480px) {
  .post-title {
    font-size: 1.7rem;
    text-align: left; /* Ajustar alinhamento do título */
  }

  .post-content {
    font-size: 0.95rem;
    line-height: 1.5; /* Ajuste o espaçamento de linhas para melhorar a leitura */
  }

  .featured-image-img {
    height: 200px; /* Diminuir ainda mais a altura da imagem */
    object-fit: cover;
  }

  .post-body-container {
    padding: 0.5rem;
    margin-top: 60px;
  }

  .post {
    margin-top: 4rem; /* Ajustar o espaçamento superior */
  }

  .wp-video {
    margin: 1rem 0; /* Ajuste o espaço ao redor do vídeo */
    width: 100%; /* Garantir que o vídeo ocupe toda a largura disponível */
    max-width: 100%; /* Limita a largura do vídeo */
  }
}

/* Estilo para o conteúdo de mídia */
.post-video {
  margin-bottom: 1.5rem;
  max-width: 100%;
}

.post-video video {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}
