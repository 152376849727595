/* 
  Estilos para a seção de notícias do site Lar São Domingos

  .news-section:
    - Define a largura, alinhamento e espaçamento da seção de notícias.
    - Aplica cor de texto branca.

  .image-slider:
    - Define a largura e altura do slider de imagens.
    - Aplica espaçamento inferior.

  @media (max-width: 768px):
    - Ajusta a altura do slider de imagens em telas menores.

  .separator-container:
    - Define a largura, exibição e alinhamento do separador.
    - Aplica margem e estilo aos elementos internos.

  .news-grid:
    - Define o layout de grid para as notícias.
    - Aplica espaçamento e largura.

  @media (max-width: 768px):
    - Ajusta o layout da grid para uma coluna em telas menores.
    - Reduz o espaçamento entre os itens.

  .news-item:
    - Define estilo, alinhamento e comportamento dos itens de notícia.
    - Aplica efeito de zoom ao passar o mouse.

  .news-item.large:
    - Ajusta o tamanho e estilo dos itens grandes de notícia.

  .news-item::before:
    - Aplica uma sobreposição de cor escura nos itens de notícia.

  .news-item-title:
    - Define o estilo dos títulos das notícias.
    - Aplica sombra ao texto.

  .news-item.large .news-item-title:
    - Ajusta o tamanho da fonte dos títulos dos itens grandes.

  .news-item:not(.large) .news-item-title:
    - Ajusta o tamanho da fonte dos títulos dos itens não grandes.

  .news-image:
    - Define o estilo das imagens dos itens de notícia.
    - Aplica borda arredondada e margem inferior.

  @media (max-width: 768px):
    - Ajusta a altura máxima das imagens em telas menores.

  .post-content:
    - Define o estilo do conteúdo do post.
    - Aplica padding e cor do texto.

  .see-more-button:
    - Define o estilo do botão "Ver mais".
    - Aplica transição e efeito de hover.

  @media (max-width: 768px):
    - Ajusta o tamanho e padding do botão em telas menores.

  @media (max-width: 480px):
    - Ajusta o tamanho da fonte do título do separador em telas muito pequenas.
    - Ajusta o tamanho e fonte do botão em telas muito pequenas.
    - Remove a altura fixa dos itens de notícia.
    - Ajusta ainda mais a altura das imagens em dispositivos pequenos.
*/
/* Estilo da seção de notícias */
.news-section {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  color: #FFFFFF;
}

/* Ajuste do image-slider para ser maior que os demais elementos */
.image-slider {
  width: 100%;
  height: 900px; /* Definido para ser significativamente maior */
  margin-bottom: 30px; /* Espaçamento entre o slider e o próximo conteúdo */
}

/* Responsividade: Ajuste do image-slider em telas pequenas */
@media (max-width: 768px) {
  .image-slider {
    height: 500px; /* Reduz a altura do slider em telas menores */
  }
}

/* Separador */
.separator-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 2rem;
}

.separator-container div {
  border: 2px solid #0099ff;
  border-radius: 5px;
  width: 100%;
  margin: 1rem;
}

.separator-container h2 {
  width: 60rem;
  font-size: 2rem;
  font-weight: bold;
  color:#0099ff
}

/* Grid de Notícias */
.news-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: auto auto;
  gap: 20px;
  margin: 0 auto;
  width: 95%;
}

/* Ajuste da grid para telas menores */
@media (max-width: 768px) {
  .news-grid {
    grid-template-columns: 1fr; /* Em telas pequenas, mostra os itens em uma coluna */
    gap: 10px; /* Reduz o espaçamento entre os itens */
  }
}

/* Estilo dos itens de notícia */
.news-item {
  cursor: pointer;
  border-radius: 5px;
  height: 340px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.news-item:hover .background-zoom {
  transform: scale(1.06);
}

/* Ajuste para o item grande */
.news-item.large {
  height: 700px;
  grid-row: span 2;
  color: #FFFFFF;

}

/* Sobreposição de cor escura no item de notícia */
.news-item::before {
  border-radius: 5px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  pointer-events: none;
}

/* Títulos das notícias */
.news-item-title {
  color: #FFFFFF;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.news-item.large .news-item-title {
  font-size: 1.5rem;
  color:#FFFFFF;
}

.news-item:not(.large) .news-item-title {
  font-size: 1rem;
  color:#FFFFFF;

}

/* Imagem do item de notícia */
.news-image {
  width: 100%;
  height: auto;
  max-height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Responsividade: ajuste das imagens */
@media (max-width: 768px) {
  .news-image {
    max-height: 200px; /* Ajusta a altura das imagens em telas menores */
  }
}

/* Conteúdo do post */
.post-content {
  padding: 20px;
  text-align: left;
  position: relative;
  color: #FFFFFF;

}

/* Botão "Ver mais" */
.see-more-button {
  padding: 20px 40px;
  font-size: 1rem;
  background-color: #0099ff;
  color: white;
  border: none;
  width: 20rem;
  font-size: 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
}

/* Efeito de hover no botão "Ver mais" */
.see-more-button:hover {
  transform: scale(1.05);
}

/* Responsividade: ajustes para telas menores */
@media (max-width: 768px) {
  .see-more-button {
    width: 100%; /* Botão ocupa toda a largura em telas pequenas */
    padding: 15px 30px; /* Ajuste do padding do botão em telas pequenas */
  }
}

@media (max-width: 480px) {
  .separator-container h2 {
    font-size: 1.5rem; /* Tamanho da fonte do título ajustado em telas muito pequenas */
  }

  .see-more-button {
    width: 100%; /* Ajuste do tamanho do botão em telas muito pequenas */
    font-size: 1rem; /* Ajuste do tamanho da fonte do botão em telas muito pequenas */
  }

  /* Ajuste das imagens no grid */
  .news-item {
    height: auto; /* Remove a altura fixa para as notícias */
  }

  .news-image {
    max-height: 150px; /* Ajusta ainda mais a altura das imagens em dispositivos pequenos */
  }
}
