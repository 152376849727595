/* 
  Estilos para a seção "About" do site.

  .about-section:
    - Define um contêiner flexível para centralizar e alinhar os itens.
    - Cor do texto branca, padding de 20px e largura de 100%.
    - Flex-wrap para permitir ajuste dos itens em telas menores.

  .about-text:
    - Contêiner flexível em coluna para centralizar e alinhar o texto à esquerda.
    - Largura de 60% e padding de 20px.

  .about-text h2:
    - Tamanho da fonte de 2.5rem, peso da fonte 700 e padding de 20px.

  .about-text p:
    - Tamanho da fonte de 1.2rem, peso da fonte 400, alinhamento justificado e padding de 10px 20px.

  .about-text ul:
    - Lista com estilo de disco, padding à esquerda de 40px, tamanho da fonte de 1.2rem e peso da fonte 400.

  .about-text li:
    - Margem inferior de 8px.

  .about-image:
    - Contêiner flexível para centralizar a imagem.
    - Padding de 20px e largura de 40%.

  .about-image img:
    - Largura de 100%, altura automática, ajuste de objeto para cobrir e borda branca sólida de 10px.

  @media (max-width: 1024px):
    - Ajusta a largura das colunas de texto e imagem para 50%.

  @media (max-width: 768px):
    - Alinha os itens verticalmente e ajusta o texto para a esquerda.
    - Largura das colunas de texto e imagem para 100% e padding de 15px.
    - Reduz o tamanho da fonte do título, parágrafo e lista.
    - Ajusta o padding da lista para 20px.

  @media (max-width: 480px):
    - Reduz ainda mais o tamanho da fonte do título, parágrafo e lista.
    - Ajusta o padding da lista para 15px.
    - Reduz a largura da borda da imagem para 5px.
*/


.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 20px;
  width: 100%;
  flex-wrap: wrap; /* Para permitir que os itens se ajustem em telas menores */
  
}

.about-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  padding: 20px;
  max-width: 1200px;
}

.about-text h2 {
  font-size: 2.5rem;
  font-weight: 700;
  padding: 20px;
}

.about-text p {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: justify;
  padding: 10px 20px;
}

.about-text ul {
  list-style: disc;
  padding-left: 40px;
  font-size: 1.2rem;
  font-weight: 400;
}

.about-text li {
  margin-bottom: 8px;
}

.about-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 40%;
  max-width: 600px;
}

.about-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: #fff solid 10px;
}

/* Media Query para dispositivos menores */
@media (max-width: 1024px) {
  .about-text {
    width: 50%; /* Ajuste a largura da coluna de texto */
  }

  .about-image {
    width: 50%; /* Ajuste a largura da coluna de imagem */
  }
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column; /* Alinhar os itens na vertical */
    text-align: left;
  }

  .about-text {
    width: 100%; /* Tornar a largura 100% em telas menores */
    padding: 15px;
  }

  .about-image {
    width: 100%; /* Tornar a largura 100% da imagem em telas menores */
  }

  .about-text h2 {
    font-size: 2rem; /* Reduzir o tamanho do título */
  }

  .about-text p,
  .about-text ul {
    font-size: 1rem; /* Reduzir o tamanho da fonte do parágrafo e lista */
  }

  .about-text ul {
    padding-left: 20px; /* Ajuste do padding para o conteúdo em telas menores */
  }
}

@media (max-width: 480px) {
  .about-text h2 {
    font-size: 1.5rem; /* Tamanho menor para o título em telas muito pequenas */
  }

  .about-text p,
  .about-text ul {
    font-size: 0.9rem; /* Ajuste do tamanho da fonte */
  }

  .about-text ul {
    padding-left: 15px; /* Menos espaço na lista */
  }

  .about-image img {
    border-width: 5px; /* Menor borda para a imagem */
  }
}

/* Media Query para dispositivos muito grandes */
@media (min-width: 1920px) {
  .about-text {
    width: 50%; /* Ajuste a largura da coluna de texto */
    padding: 30px; /* Aumentar o padding */
  }

  .about-image {
    width: 50%; /* Ajuste a largura da coluna de imagem */
    padding: 30px; /* Aumentar o padding */
  }

  .about-text h2 {
    font-size: 3rem; /* Aumentar o tamanho do título */
  }

  .about-text p,
  .about-text ul {
    font-size: 1.5rem; /* Aumentar o tamanho da fonte do parágrafo e lista */
  }

  .about-text ul {
    padding-left: 50px; /* Aumentar o padding da lista */
  }

  .about-image img {
    border-width: 15px; /* Aumentar a largura da borda da imagem */
  }
}

