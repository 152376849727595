.auditorium-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.presentation-video {
  margin-top: 20px;
  width: 100%;
  max-width: 900px;
  margin-bottom: 40px; /* Espaço maior abaixo do vídeo */
}

.presentation-video video {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombras para dar profundidade */
}

.auditorium-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: flex-start;
  padding: 0 20px;
}

.auditorium-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px; /* Card com largura menor para melhorar o layout */
  padding: 20px;
  background-color: #fff; /* Branco para maior clareza */
  border-radius: 12px; /* Bordas mais arredondadas */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1); /* Sombras mais intensas */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Efeito de transformação suave */
}

.auditorium-section:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Sombra mais forte ao passar o mouse */
}

.auditorium-image {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 15px; /* Espaço abaixo da imagem */
}

.auditorium-info h2 {
  font-size: 1.4rem;
  margin: 10px 0;
  text-align: center;
  color: #333; /* Cor mais escura para o título */
}

.auditorium-info p {
  font-size: 1rem;
  margin: 5px 0;
  text-align: left;
  color: #555; /* Cor mais suave para o texto */
}

.auditorium-info ul {
  list-style-type:circle;
  padding: 20px;
  margin-top: 10px;
}

.auditorium-info ul li {
  font-size: 1rem;
  color: #333;
  text-align: left;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .auditorium-cards {
    flex-direction: column;
    gap: 20px;
  }

  .auditorium-section {
    width: 90%; /* Aumenta o tamanho dos cards em telas menores */
    max-width: 400px; /* Limita a largura para não ficar exagerado */
  }

  .presentation-video {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .auditorium-cards {
    gap: 60px;
    justify-content: flex-start;
  }

  .auditorium-section {
    width: 500px; /* Card com largura maior para desktop */
  }

  .presentation-video {
    width: 70%;
  }
}

@media (min-width: 1600px) {
  .auditorium-cards {
    gap: 80px;
  }

  .presentation-video {
    width: 60%;
  }
}
