/* 
  Este arquivo CSS define os estilos para o componente de popup de doação.

  Animações:
  - @keyframes popupGrow: Animação para o crescimento do popup ao aparecer.

  Classes:
  - .popup-overlay: Estilo para a sobreposição do popup, cobrindo toda a tela com um fundo semi-transparente.
  - .popup-grid-position: Estilo para a posição e layout do conteúdo do popup, com um design de grade.
  - .popup-image: Estilo para a imagem dentro do popup, garantindo que ela cubra o espaço disponível.
  - .popup-text: Estilo para o texto dentro do popup, com alinhamento e espaçamento apropriados.
  - .popup-text h1: Estilo para o título dentro do popup.
  - .popup-text img: Estilo para a imagem dentro do texto do popup, garantindo responsividade.
  - .doe-button: Estilo para o botão de doação, com cores, padding e transições.

  Media Queries:
  - @media (max-width: 1024px): Ajustes de estilo para telas com largura máxima de 1024px.
  - @media (max-width: 768px): Ajustes de estilo para telas com largura máxima de 768px.
  - @media (max-width: 480px): Ajustes de estilo para telas com largura máxima de 480px.
*/
@keyframes popupGrow {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.popup-overlay:hover {
  cursor: pointer; /* Para indicar que a área do fundo pode ser clicada para fechar */
}

.popup-grid-position {
  position: absolute;
  width: 60%;  /* Ajuste para ser responsivo */
  max-width: 800px;  /* Limitar o tamanho máximo */
  height: auto;  /* Ajuste de altura automática */
  background-color: white;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;  /* Espaçamento entre as colunas */
  overflow: hidden;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  animation: popupGrow 0.3s ease-in;
  cursor: auto;
}

.popup-image {
  background-size: cover;
  background-position: center;
  height: 100%;
}

.popup-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: left;
  gap: 20px;
  background-color: #fff;
  border-radius: 20px;
}

.popup-text h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.popup-text img {
  width: 100%;  /* Tornar a imagem responsiva */
  max-width: 200px;  /* Limitar o tamanho máximo */
  height: auto;
  margin-bottom: 20px;
}

.doe-button {
  background-color: #00b2ee;
  color: white;
  border: none;
  padding: 10px 60px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.doe-button:hover {
  background-color: #0056b3;
}

/* Media Queries para Responsividade */

@media (max-width: 1024px) {
  .popup-grid-position {
    width: 80%;  /* Aumentar a largura em telas menores */
    height: auto; /* Altura automática */
    grid-template-columns: 1fr;  /* Disposição de uma única coluna */
  }

  .popup-text h1 {
    font-size: 1.5rem;  /* Reduzir o tamanho da fonte */
  }

  .popup-text img {
    max-width: 150px;  /* Ajuste do tamanho da imagem */
  }
}

@media (max-width: 768px) {
  .popup-grid-position {
    width: 90%;  /* Aumentar a largura em telas muito pequenas */
    grid-template-columns: 1fr;  /* Manter a coluna única */
    padding: 10px;  /* Ajuste de padding */
  }

  .popup-text h1 {
    font-size: 1.2rem;  /* Tamanho menor do título */
  }

  .popup-text img {
    max-width: 120px;  /* Ajustar a imagem para telas pequenas */
  }

  .doe-button {
    font-size: 1rem;  /* Tamanho da fonte do botão menor */
    padding: 8px 40px;  /* Reduzir padding do botão */
  }
}

@media (max-width: 480px) {
  .popup-grid-position {
    width: 100%;  /* Tornar o popup ocupar 100% da largura da tela */
    height: auto; /* Garantir que a altura se ajuste */
    grid-template-columns: 1fr;  /* Coluna única */
  }

  .popup-text h1 {
    font-size: 1rem;  /* Fonte menor em telas muito pequenas */
  }

  .popup-text img {
    max-width: 100px;  /* Imagem ainda menor */
  }

  .doe-button {
    font-size: 0.9rem;  /* Fonte do botão ainda menor */
    padding: 8px 30px;  /* Ajuste de padding */
  }
}
