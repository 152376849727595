/* 
  InfoSection.css

  Este arquivo de estilos define a aparência e o comportamento da seção de informações e suas caixas de informações.

  .info-section:
    - Define um contêiner flexível para centralizar e alinhar itens com espaçamento e padding.
    - Permite que os itens se movam para a linha seguinte em telas menores com flex-wrap.

  .info-box:
    - Define o estilo das caixas de informações com padding, bordas arredondadas, alinhamento de texto centralizado, largura e altura fixas.
    - Adiciona sombra e transição suave ao passar o mouse.
    - Define a cor do texto como branca e o cursor como ponteiro.

  .info-box:hover:
    - Adiciona um efeito de elevação ao passar o mouse.

  .info-icon:
    - Define o tamanho e o espaçamento dos ícones dentro das caixas de informações.

  .info-box.red, .info-box.blue, .info-box.orange, .info-box.green:
    - Define cores de fundo específicas para diferentes caixas de informações.

  .info-box h3:
    - Define o estilo dos títulos dentro das caixas de informações.

  .info-box p:
    - Define o estilo dos parágrafos dentro das caixas de informações, removendo espaçamento extra.

  .info-section a:
    - Define o estilo dos links dentro da seção de informações.

  .info-section a:hover:
    - Define o estilo dos links ao passar o mouse.

  @media (max-width: 1024px):
    - Ajusta o padding, o espaçamento entre as caixas, a largura das caixas, o tamanho dos ícones, dos títulos e dos subtítulos para telas menores.

  @media (max-width: 768px):
    - Ajusta ainda mais o padding, o espaçamento entre as caixas, a largura das caixas, o tamanho dos ícones, dos títulos e dos subtítulos para telas menores.
    - Adiciona espaçamento entre as caixas em dispositivos móveis.
*/
/* InfoSection.css */
.info-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px;
  background-color: #fff;
  margin-block: 50px;
  flex-wrap: wrap; /* Permite que os itens se movam para a linha seguinte em telas menores */
}

.info-box {
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 200px;
  height: 220px; /* Altura fixa */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.info-box:hover {
  transform: translateY(-10px);

}

.info-icon {
  font-size: 80px;
  margin-bottom: 10px;
  margin-inline: auto;
}

/* Cores de fundo diretamente nas caixas */
.info-box.red {
  background-color: #e74c3c; /* Vermelho */
}

.info-box.blue {
  background-color: #3498db; /* Azul */
}

.info-box.orange {
  background-color: #f39c12; /* Laranja */
}

.info-box.green {
  background-color: #2ecc71; /* Verde */
}

.info-box h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: white;
}

.info-box p {
  font-size: 14px;
  color: white;
  margin: 0; /* Para evitar espaçamento extra */
}

.info-section a {
  text-decoration: none;
  color: #000;
}

.info-section a:hover {
  color: #130080;
  text-decoration: none;
}

/* Responsividade */
@media (max-width: 1024px) {
  .info-section {
    padding: 20px; /* Reduz o padding em telas menores */
    gap: 15px; /* Ajusta o espaço entre as caixas */
  }

  .info-box {
    width: 100%; /* Cada caixa ocupa até 45% da largura em telas menores */
    max-width: 320px; /* Define uma largura máxima para as caixas */
  }

  .info-icon {
    font-size: 70px; /* Ajusta o tamanho do ícone */
  }

  .info-box h3 {
    font-size: 16px; /* Ajusta o tamanho do título */
  }

  .info-box p {
    font-size: 13px; /* Ajusta o tamanho do subtítulo */
  }
}

@media (max-width: 768px) {
  .info-section {
    padding: 20px; /* Reduz o padding em telas menores */
    gap: 10px; /* Menos espaço entre as caixas */
    justify-content: center; /* Garante que as caixas fiquem centralizadas */
  }

  .info-box {
    width: 100%; /* Cada caixa ocupa 100% da largura em telas menores */
    max-width: 300px; /* Define uma largura máxima para as caixas */
    margin-bottom: 20px; /* Adiciona um espaçamento entre as caixas em dispositivos móveis */
  }

  .info-icon {
    font-size: 60px; /* Reduz o tamanho do ícone */
  }

  .info-box h3 {
    font-size: 16px; /* Ajusta o tamanho do título */
  }

  .info-box p {
    font-size: 12px; /* Ajusta o tamanho do subtítulo */
  }
}
