/* 

  Estilos básicos para a página de doações:
  - .donation-page: Define o layout da página de doações usando grid, com espaçamento e cores de fundo e texto.
  - .donation-section.donation-info: Estiliza a seção de informações de doação com fundo branco, bordas, sombras e espaçamento.
  - .donation-section.donation-info .info-section-account: Estiliza a seção de conta com fundo claro, bordas e sombras.
  - .donation-section h1: Centraliza e ajusta o tamanho do título para telas menores.
  - .donation-section.donation-info #qr-code: Estiliza a seção do QR Code para centralização e responsividade.
  - .donation-section.donation-info #qr-code img: Ajusta a largura máxima e bordas da imagem do QR Code.
  - .donation-section.donation-info a: Estiliza links com cor, peso da fonte e transições.
  - .donation-section.instructions: Estiliza a seção de instruções com fundo verde, bordas, sombras e espaçamento.
  - .donation-section.instructions video: Ajusta a largura máxima e bordas dos vídeos.
  - .donation-section.instructions a: Estiliza links na seção de instruções com cor e transições.
  - .donation-section.instructions #chamada-video: Estiliza o texto de chamada do vídeo.
  - .donation-section.instructions #agradecimento: Estiliza o texto de agradecimento.
  - .donation-section.instructions .listagem-tutorial: Define o layout da listagem de tutoriais.
  - .donation-section.instructions .listagem-tutorial ul: Remove o padding e define o estilo da lista.
  - .donation-section.instructions .cta-button: Estiliza o botão de chamada para ação com cores, bordas e transições.

  Media Queries para dispositivos móveis:
  - @media (max-width: 768px): Ajustes para telas menores que 768px, incluindo padding, layout de grid, tamanhos de fonte e espaçamento.
  - @media (max-width: 480px): Ajustes adicionais para telas menores que 480px, incluindo padding, tamanhos de fonte e largura de imagens.
*/

/* Estilos básicos para a página de doações */
.donation-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  background-color: #f8f9fa;
  color: #333;
  padding: 3rem;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  justify-items: center;
  /* Nenhuma alteração no layout desktop */
}

.donation-section.donation-info {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.donation-section.donation-info .info-section-account {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border: #bbbbbb solid 0.5px;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Ajustes para garantir que o título fique bem posicionado em telas pequenas */
.donation-section h1 {
  text-align: center;
  font-size: 1.5rem; /* Tamanho de fonte ajustado para telas menores */
}

/* Responsividade para o QR Code */
.donation-section.donation-info #qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border: none;
  box-shadow: none;
  flex-wrap: wrap; /* Garante que os itens se ajustem */
}

.donation-section.donation-info #qr-code img {
  max-width: 100%;
  width: 200px;
  border-radius: 8px;
}

/* Link responsivo */
.donation-section.donation-info a {
  color: #000ba9;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem; /* Ajustar tamanho de fonte para telas menores */
}

.donation-section.donation-info a:hover {
  font-size: 1.1rem;
  transition: 0.3s;
}

/* Responsividade para a seção de instruções */
.donation-section.instructions {
  background-color: #008000;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  color: white;
  gap: 40px;
  flex-wrap: nowrap;
  align-content: center;
}

.donation-section.instructions video {
  max-width: 100%;
  border-radius: 8px;
}

.donation-section.instructions a {
  color: white;
  text-decoration: none;
}

.donation-section.instructions a:hover {
  font-size: 1.1rem;
  transition: 0.3s;
  font-weight: bold;
}

.donation-section.instructions #chamada-video {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.donation-section.instructions #agradecimento {
  font-size: 1.2rem;
  text-align: center;
}

.donation-section.instructions .listagem-tutorial{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}

.donation-section.instructions .listagem-tutorial ul{
  list-style-type: numeric;
  padding: 0;
}

.donation-section.instructions .cta-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  font-weight: bold;
  text-decoration: none;
  transition: 0.3s;
  color: #008000;
}

/* Media Queries para dispositivos móveis */
@media (max-width: 768px) {
  /* Alterando para layout mobile, mas sem afetar o desktop */
  .donation-page {
    padding: 1.5rem; /* Menos padding em telas menores */
    grid-template-columns: 1fr; /* Usando uma coluna em telas pequenas */
  }

  .donation-section.donation-info h1 {
    font-size: 1.2rem; /* Ajusta o título */
  }

  .donation-section.donation-info a {
    font-size: 0.9rem; /* Ajusta o tamanho do link */
  }

  .donation-section.instructions {
    padding: 1.5rem; /* Menos padding */
    gap: 30px; /* Menor espaçamento entre os elementos */
  }

  .donation-section.instructions video {
    width: 100%; /* Garante que o vídeo não ultrapasse a largura */
  }

  /* Alterações específicas para o layout do QR code */
  .donation-section.donation-info #qr-code {
    flex-direction: column; /* Para que a imagem e o link fiquem em coluna */
    gap: 5px;
  }
}

@media (max-width: 480px) {
  /* Ajuste para dispositivos móveis muito pequenos */
  .donation-page {
    padding: 1rem;
  }

  .donation-section.donation-info h1 {
    font-size: 1rem; /* Ajuste para títulos em dispositivos muito pequenos */
  }

  .donation-section.instructions {
    padding: 1rem;
    gap: 20px;
  }

  .donation-section.donation-info #qr-code img {
    width: 150px; /* Ajuste a largura da imagem do QR code */
  }

  .donation-section.donation-info a {
    font-size: 0.8rem; /* Ajuste para links menores em telas muito pequenas */
  }
}
