/* 

  .ActivitiesList:
    - Define um contêiner flexível em coluna para a lista de atividades.
    - Centraliza o conteúdo e aplica uma margem vertical de 40px.

  .list-activities:
    - Define um contêiner flexível em linha para as atividades.
    - Centraliza o conteúdo, alinha os itens ao centro e aplica um espaçamento de 20px entre eles.

  .activities:
    - Define um contêiner flexível em coluna para cada atividade.
    - Alinha o conteúdo ao topo e ao centro, com um espaçamento de 10px entre os elementos.
    - Define largura de 350px, altura de 600px, padding inferior de 20px e bordas arredondadas.
    - Aplica cor de texto branca.

  .activities h3:
    - Define o tamanho da fonte como 1.2rem e peso da fonte como 700.

  .activities p:
    - Define o tamanho da fonte como 0.9rem, peso da fonte como 400 e alinhamento justificado.
    - Aplica padding de 20px.

  .activities img:
    - Define largura de 100%, altura de 300px e ajuste de objeto para cobrir.
    - Aplica bordas arredondadas na parte superior.

  .activities:first-child:
    - Aplica cor de fundo #f2994a.

  .activities:nth-child(2):
    - Aplica cor de fundo #e8403c.

  .activities:nth-child(3):
    - Aplica cor de fundo #4ba64f.

  .activities:nth-child(4):
    - Aplica cor de fundo #ffa625.

  @media (max-width: 1024px):
    - Ajusta a largura das atividades para 350px.

  @media (max-width: 768px):
    - Altera a direção do contêiner de atividades para coluna e justifica o conteúdo com espaço ao redor.
    - Ajusta a margem da lista de atividades para 20px.
    - Reduz a largura das atividades para 90% e ajusta a altura para auto.
    - Reduz a altura das imagens para 250px.
    - Ajusta o tamanho da fonte dos títulos para 1rem.
    - Ajusta o tamanho da fonte dos parágrafos para 0.8rem.

  @media (max-width: 480px):
    - Altera a direção do contêiner de atividades para coluna e centraliza o conteúdo.
    - Ajusta a largura das atividades para 100% e a altura para auto.
    - Reduz a altura das imagens para 200px.
    - Ajusta o tamanho da fonte dos títulos para 0.9rem.
    - Ajusta o tamanho da fonte dos parágrafos para 0.75rem.
*/
.ActivitiesList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0;
}

.list-activities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.activities {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinhamento no topo */
  align-items: center;
  gap: 10px;
  width: 350px;
  padding: 0px 0px 20px 0px;
  border-radius: 40px;
  color: #fff;
  height: 630px;
}

.activities h3 {
  font-size: 1.2rem;
  font-weight: 700;
}

.activities p {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: justify;
  padding: 0 20px;
}

.activities img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 40px 40px 0 0;
}

.activities:first-child {
  background-color: #f2994a;
}

.activities:nth-child(2) {
  background-color: #e8403c;
}

.activities:nth-child(3) {
  background-color: #4ba64f;
}

.activities:nth-child(4) {
  background-color: #ffa625;
}

/* Media Query para telas maiores que 1024px */
@media (max-width: 1024px) {
  .activities {
    width: 350px; /* Ajuste de largura */
  }
}

/* Media Query para tablets e telas menores (768px ou menos) */
@media (max-width: 768px) {
  .list-activities {
    justify-content: space-around;
    flex-direction: column; /* Empilhar atividades em dispositivos menores */
  }

  .ActivitiesList {
    margin: 20px ; /* Ajuste de margem */
  }

  .activities {
    width: 90%; /* Reduzindo a largura das atividades */
    height: auto; /* Aumenta a flexibilidade das atividades */
  }

  .activities img {
    height: 250px; /* Imagens menores para dispositivos menores */
  }

  .activities h3 {
    font-size: 1rem; /* Tamanho de fonte ajustado */
  }

  .activities p {
    font-size: 0.8rem; /* Ajuste de tamanho da fonte para o parágrafo */
  }
}

/* Media Query para celulares e telas muito pequenas (480px ou menos) */
@media (max-width: 480px) {
  .list-activities {
    justify-content: center;
    flex-direction: column; /* Empilhar as atividades em uma coluna */
  }

  .activities {
    width: 100%; /* Atividades ocupando toda a largura disponível */
    height: auto; /* Ajuste da altura */
  }

  .activities img {
    height: 200px; /* Ajuste na altura da imagem para celulares */
  }

  .activities h3 {
    font-size: 0.9rem; /* Menor tamanho para o título */
  }

  .activities p {
    font-size: 0.75rem; /* Ajuste da fonte do parágrafo */
  }
}

/* Media Query para telas muito grandes (1920px ou mais) */
@media (min-width: 1940px) {
  .activities {
    width: 500px; /* Aumenta a largura das atividades */
    height: 800px; /* Aumenta a altura das atividades */
  }

  .activities img {
    height: 450px; /* Aumenta a altura das imagens */
  }

  .activities h3 {
    font-size: 1.8rem; /* Aumenta o tamanho da fonte dos títulos */
  }

  .activities p {
    font-size: 1.2rem; /* Aumenta o tamanho da fonte dos parágrafos */
  }
}

