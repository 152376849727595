.contact-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    color: #000;
    padding: 40px;
    gap: 40px;
    width: 100%;
}

.contact-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 1240px;
}

.contact-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
}

.contact-section h2 {
    font-size: 2rem;
    font-weight: 700;
}

.contact-section p {
    font-size: 1.2rem;
    font-weight: 400;
    width: 70%;
}

.contact-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: #FFF;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: none;
    width: 100%;
    height: 50px;
}

.contact-buttons button img {
    width: 30px;
}

.contact-buttons #email-button {
    background-color: #205bff;
}

.contact-buttons #whatsapp-button {
    background-color: #119436;
}

.contact-buttons #email-button:hover {
    background-color: #1a4dda;
}

.contact-buttons #whatsapp-button:hover {
    background-color: #0f7d2e;
}

/* Media Queries for Responsiveness */
@media (max-width: 1080px) {
    .contact-section {
        flex-direction: column;
        padding: 20px;
        gap: 20px;
        width: 100%;
    }

    .contact-content {
        flex-direction: column;
        gap: 20px;
    }

    .contact-section h2 {
        font-size: 1.5rem; /* Tamanho de título ajustado */
        text-align: center; /* Centralizar título */
    }

    .contact-section p {
        font-size: 1rem; /* Reduzir o tamanho da descrição */
        width: 100%;
        text-align: center; /* Centralizar o texto */
    }

    .contact-buttons {
        width: 100%;
        align-items: center; /* Alinha os botões ao centro */
        flex-direction: column; /* Coloca os botões abaixo do texto */
        gap: 10px; /* Menor espaçamento entre os botões */
    }

    .contact-buttons button {
        font-size: 1rem;
        height: 40px;
    }

    .contact-buttons button img {
        width: 25px;
    }
}

@media (max-width: 480px) {
    .contact-section {
        padding: 10px;
        gap: 15px; /* Menor espaçamento entre os elementos */
    }

    .contact-section h2 {
        font-size: 1.2rem; /* Título ainda menor em telas muito pequenas */
        text-align: center; /* Centralizar título */
    }

    .contact-section p {
        font-size: 0.9rem; /* Reduzir o texto para telas pequenas */
        text-align: center; /* Centralizar o texto */
    }

    .contact-buttons button {
        font-size: 0.9rem;
        height: 35px; /* Botões mais compactos */
    }

    .contact-buttons button img {
        width: 20px; /* Ícones menores em telas pequenas */
    }
}
