/* 
  Estilos para a seção de localização (.location)
  - margin-top: Define a margem superior da seção.
  - display: Define o layout flexível.
  - flex-direction: Define a direção dos itens como coluna.
  - justify-content: Centraliza os itens verticalmente.
  - align-items: Alinha os itens ao início horizontalmente.
  - background-color: Define a cor de fundo.
  - max-width: Define a largura máxima da seção.
  - gap: Define o espaçamento entre os itens.
  - padding: Adiciona espaçamento interno.

  Estilos para o contêiner de localização (.location-container)
  - display: Define o layout flexível.
  - flex-direction: Define a direção dos itens como linha.
  - justify-content: Centraliza os itens horizontalmente.
  - align-items: Centraliza os itens verticalmente.
  - gap: Define o espaçamento entre os itens.
  - width: Define a largura total do contêiner.
  - flex-wrap: Permite que os itens quebrem para a linha seguinte.

  Estilos para a informação de visita (.visit-info)
  - display: Define o layout flexível.
  - flex-direction: Define a direção dos itens como coluna.
  - justify-content: Centraliza os itens verticalmente.
  - align-items: Alinha os itens ao início horizontalmente.
  - gap: Define o espaçamento entre os itens.
  - width: Define a largura total.
  - max-width: Define a largura máxima da caixa.

  Estilos para informações relevantes (.relevant-infos)
  - display: Define o layout flexível.
  - flex-direction: Define a direção dos itens como coluna.
  - justify-content: Centraliza os itens verticalmente.
  - align-items: Alinha os itens ao início horizontalmente.
  - gap: Define o espaçamento entre os itens.
  - width: Define a largura total.
  - max-width: Define a largura máxima da caixa.

  Responsividade para telas menores que 768px
  - .location: Ajusta o padding.
  - .location-container: Altera a direção dos itens para coluna e ajusta o espaçamento.
  - .visit-info, .relevant-infos: Ajusta a largura máxima e o espaçamento interno.

  Responsividade para telas entre 768px e 1024px
  - .location-container: Ajusta o espaçamento entre as caixas.
  - .visit-info, .relevant-infos: Ajusta a largura máxima.

  Estilos para o contêiner do mapa (.map-container)
  - position: Define a posição relativa.
  - width: Define a largura total.
  - padding-bottom: Define a proporção de 16:9.
  - height: Define a altura como zero.
  - overflow: Esconde o conteúdo que ultrapassa.

  Estilos para o iframe do mapa (.map-container iframe)
  - position: Define a posição absoluta.
  - top, left: Posiciona o iframe no topo e à esquerda.
  - width: Define a largura total do iframe.
  - height: Define a altura total do iframe.
  - border: Remove a borda do iframe.
*/
/* .location section */
.location {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    max-width: 1240px;
    gap: 20px;
    padding: 20px; /* Adiciona um pouco de padding para afastar do limite da tela */
  }
  
  /* .location-container */
  .location-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%; /* Garante que a largura ocupe toda a tela */
    flex-wrap: wrap; /* Permite que os itens se movam para a linha seguinte se necessário */
  }
  
  /* .visit-info */
  .visit-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    max-width: 500px; /* Limita o tamanho máximo da caixa para evitar que ela fique muito larga */
  }
  
  /* .relevant-infos */
  .relevant-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    max-width: 500px; /* Limita o tamanho máximo da caixa para evitar que ela fique muito larga */
  }
  
  /* Responsividade para telas menores que 768px */
  @media (max-width: 768px) {
    .location {
      padding: 20px; /* Reduz o padding em telas menores */
    }
  
    .location-container {
      flex-direction: column; /* Empilha os itens em uma coluna em telas pequenas */
      gap: 20px; /* Aumenta o espaço entre os itens */
    }
  
    /* Ajustes no tamanho das caixas */
    .visit-info, .relevant-infos {
      max-width: 100%; /* Permite que as caixas ocupem 100% da largura */
    }
  
    .visit-info {
      gap: 15px; /* Ajusta o gap entre os elementos dentro da visita */
    }
  
    .relevant-infos {
      gap: 10px; /* Ajusta o gap entre os elementos de informações relevantes */
    }
  }
  
  /* Responsividade para telas entre 768px e 1024px */
  @media (max-width: 1024px) {
    .location-container {
      gap: 30px; /* Ajusta o espaçamento entre as caixas */
    }
  
    .visit-info, .relevant-infos {
      max-width: 80%; /* Ajusta a largura para 80% em telas médias */
    }

    /* Responsividade do mapa */
.map-container {
    position: relative;
    width: 100%; /* O contêiner ocupa toda a largura disponível */
    padding-bottom: 56.25%; /* Proporção de 16:9 (9/16 = 0.5625 = 56.25%) */
    height: 0; /* Zera a altura para permitir que o padding defina a altura */
    overflow: hidden;
  }
  
  .map-container iframe {
    position: absolute; /* Posiciona o iframe de forma absoluta dentro do contêiner */
    top: 0;
    left: 0;
    width: 100%; /* O iframe ocupa toda a largura do contêiner */
    height: 100%; /* A altura do iframe será ajustada para ocupar o contêiner */
    border: 0;
  }
  
  }

  /* Responsividade para telas acima de 1940px */
  @media (min-width: 1940px) {
    .location {
      max-width: 1600px; /* Aumenta a largura máxima da seção */
      padding: 40px; /* Aumenta o padding para dar mais espaço interno */
    }

    .location-container {
      gap: 60px; /* Aumenta o espaçamento entre os itens */
    }

    .visit-info, .relevant-infos {
      max-width: 700px; /* Aumenta a largura máxima das caixas */
    }
  }
  