/* 

  Este arquivo CSS estiliza a barra de navegação (navbar) do site. 
  Ele define a aparência e o comportamento dos elementos da navbar, incluindo o logo, o menu de navegação e o botão de menu tipo "burger" para dispositivos móveis.

  Classes principais:
  - .navbar: Define a estrutura principal da barra de navegação, incluindo layout flexível, espaçamento e cores.
  - .navbar-logo img: Estiliza a imagem do logo na navbar.
  - .navbar-menu: Define o estilo do menu de navegação, incluindo layout flexível e remoção de estilos padrão de lista.
  - .navbar-menu li: Estiliza os itens da lista do menu de navegação.
  - .navbar-menu a: Estiliza os links do menu de navegação, incluindo cores, tamanhos de fonte e transições de cor.
  - .burger: Estiliza o botão de menu tipo "burger" para dispositivos móveis.

  Classes de estado:
  - .nav-inicio:hover, .nav-inicio.active: Define o estilo de hover e estado ativo para o item "Início".
  - .nav-institucional:hover, .nav-institucional.active: Define o estilo de hover e estado ativo para o item "Institucional".
  - .nav-atividades:hover, .nav-atividades.active: Define o estilo de hover e estado ativo para o item "Atividades".
  - .nav-abrace:hover, .nav-abrace.active: Define o estilo de hover e estado ativo para o item "Abrace".
  - .nav-doacoes:hover, .nav-doacoes.active: Define o estilo de hover e estado ativo para o item "Doações".
  - .nav-auditorios:hover, .nav-auditorios.active: Define o estilo de hover e estado ativo para o item "Auditórios".

  Media Queries:
  - @media (max-width: 1050px): Ajusta o layout da navbar para telas menores, incluindo a exibição do botão de menu tipo "burger" e a transformação do menu de navegação em um menu vertical que aparece abaixo da navbar.
*/
/* Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0px 20px;
  height: 60px;
  max-width: 1200px;
  margin-inline: auto;
}

.navbar-logo img {
  height: 40px; /* Ajuste o tamanho da logo conforme necessário */
  cursor: pointer;
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  height: 100%;
}

.navbar-menu li {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 100%;
}

.navbar-menu a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 12px;
  transition: background-color 0.3s ease;
}

/* Cores de hover e active */
.nav-inicio:hover,
.nav-inicio.active {
  background-color: #00aaff;
  color: white;
}
.nav-institucional:hover,
.nav-institucional.active {
  background-color: orange;
  color: white;
}
.nav-atividades:hover,
.nav-atividades.active {
  background-color: darkblue;
  color: white;
}
.nav-abrace:hover,
.nav-abrace.active {
  background-color: red;
  color: white;
}
.nav-doacoes:hover,
.nav-doacoes.active {
  background-color: green;
  color: white;
}
.nav-auditorios:hover,
.nav-auditorios.active {
  background-color: yellow;
  color: black; /* Para manter o contraste */
}

/* Estilos do burger menu */
.burger {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 1050px) {
  .navbar {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0px 20px;
    height: 60px;
    max-width: 1200px;
    margin-inline: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    /* gap: 202px; */
    width: 100%;
  }

  .navbar-menu {
    display: none; /* Esconde o menu padrão em telas pequenas */
    flex-direction: column;
    position: absolute;
    top: 60px; /* Abaixo da navbar */
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1000; /* Para garantir que o menu fique sobre outros elementos */
  }

  .navbar-menu li {
    width: 100%;
    height: 60px;
  }

  .navbar-menu a {
    width: 100%;
    height: 50px;
    padding: 0;
  }

  .navbar-menu.open {
    display: flex; /* Exibe o menu quando está aberto */
  }

  .burger {
    display: block; /* Exibe o botão do burger em telas pequenas */
  }
}
