/* 

  Responsividade e Estilos Gerais:
  - .slider-container: Define a largura e a posição relativa do contêiner do slider.

  Estilos das Setas:
  - .custom-arrow: Estiliza as setas de navegação do slider, incluindo posição, cor, tamanho e cursor.
  - .next-arrow: Posiciona a seta de próxima à direita.
  - .prev-arrow: Posiciona a seta de anterior à esquerda.

  Estilo dos Itens do Slider:
  - .slider-item: Define a posição relativa dos itens do slider.
  - .slider-image: Define a largura, altura e ajuste das imagens do slider.

  Sobreposição de Texto:
  - .slider-overlay: Estiliza a sobreposição de texto no slider, incluindo posição, cor, sombra e alinhamento.
  - .slider-overlay h2: Estiliza o título na sobreposição de texto.
  - .slider-overlay p: Estiliza o parágrafo na sobreposição de texto.

  Responsividade:
  - @media (max-width: 1024px): Ajusta a altura da imagem, tamanho do título, parágrafo e setas para telas menores.
  - @media (max-width: 768px): Ajusta a altura da imagem, tamanho do título, parágrafo e setas para telas ainda menores.
  - @media (max-width: 480px): Ajusta a altura da imagem, tamanho do título e parágrafo para telas pequenas.

  Estilos das Miniaturas (Thumbnails):
  - .slick-thumb-container: Define a posição, largura e alinhamento do contêiner de miniaturas.
  - .slick-thumb: Define o espaçamento, padding e overflow das miniaturas.
  - .slick-thumb li: Define o tamanho, estilo e transição das miniaturas.
  - .slick-thumb li img: Define o tamanho, borda e sombra das imagens das miniaturas.
  - .slick-thumb li.slick-active img: Estiliza a miniatura ativa com borda e transformação.
  - .slick-thumb li:active: Estiliza a miniatura clicada com transformação.

  Botões para navegação horizontal nas Thumbnails:
  - .thumbnail-nav-button: Estiliza os botões de navegação das miniaturas, incluindo fundo, cor, tamanho, borda e cursor.
  - .thumbnail-nav-prev: Define o espaçamento à direita do botão anterior.
  - .thumbnail-nav-next: Define o espaçamento à esquerda do botão próximo.
  - .thumbnail-nav-button:hover: Estiliza o efeito de hover para os botões.
*/
/* Responsividade e Estilos Gerais */
.slider-container {
  width: 100%;
  position: relative;
}

/* Estilos das Setas */
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 2rem;
  border-radius: 50%;
  padding: 10px;
}

.next-arrow {
  right: 15px;
}

.prev-arrow {
  left: 15px;
}

/* Estilo dos Itens do Slider */
.slider-item {
  position: relative;
}

.slider-image {
  width: 100%;
  height: 90vh;
  min-height: 300px;
  object-position: center center;
  object-fit: cover;
  position: relative; /* Adiciona posição relativa para o overlay */
}

/* Sobreposição de Texto */
.slider-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
  padding: 0 20px;
  z-index: 2;
  font-family: Arial, sans-serif;
}

.slider-overlay h2 {
  font-size: 4rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.slider-overlay p {
  font-size: 1.5rem;
}

/* Responsividade */
@media (max-width: 1024px) {
  .slider-image {
    height: 60vh;
  }

  .slider-overlay h2 {
    font-size: 3.5rem;
  }

  .slider-overlay p {
    font-size: 1.3rem;
  }

  .custom-arrow {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .slider-image {
    height: 400px;
  }

  .slider-overlay h2 {
    font-size: 3rem;
  }

  .slider-overlay p {
    font-size: 1.2rem;
  }

  .custom-arrow {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .slider-image {
    height: 300px; /* Ajusta a altura das imagens em telas pequenas */
  }

  .slider-overlay h2 {
    font-size: 2rem; /* Reduz o tamanho do título */
  }

  .slider-overlay p {
    font-size: 1rem; /* Reduz o tamanho do texto */
  }
}

/* Estilos das Miniaturas (Thumbnails) */
.slick-thumb-container {
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 100%;
  max-width: 80%;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.slick-thumb {
  display: flex;
  gap: 10px; /* Espaçamento entre as miniaturas */
  padding: 10px;
  height: 100%;
  overflow-x: hidden; /* Rolagem horizontal para as miniaturas */
  overflow-y: hidden; /* Rolagem horizontal para as miniaturas */
}

.slick-thumb li {
  flex: 0 0 auto; /* Impede que os itens encolham ou estiquem */
  width: 80px;
  height: 80px;
  list-style: none;
  cursor: pointer;
  transition: transform 0.3s ease; /* Transição suave para transformação */
}

.slick-thumb li img {
  width: 100%;
  height: 100%;
  border-radius: 8px; /* Bordas arredondadas nas miniaturas */
  border: 2px solid transparent; /* Borda invisível padrão */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Sombra suave nas miniaturas */
}

.slick-thumb li.slick-active img {
  border-color: #007bff; /* Cor da borda quando a miniatura está ativa */
  transform: scale(1.1); /* Aumenta a miniatura ativa para destacá-la */
}

.slick-thumb li:active {
  transform: scale(1.1); /* Aumenta a miniatura clicada */
}

/* Botões para navegação horizontal nas Thumbnails */
.thumbnail-nav-button {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Fundo semi-transparente para botões */
  color: white;
  font-size: 1.5rem;
  padding: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Tornando os botões redondos */
  cursor: pointer;
  z-index: 10;
}

.thumbnail-nav-prev {
  margin-right: 10px; /* Espaçamento à direita do botão anterior */
}

.thumbnail-nav-next {
  margin-left: 10px; /* Espaçamento à esquerda do botão próximo */
}

.thumbnail-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Efeito de hover para os botões */
}
